<template>
  <v-autocomplete
    v-model="selectOption"
    :items="products"
    :loading="loading"
    :search-input.sync="search"
    class="filter-select text-sm"
    hide-details
    hide-no-data
    item-text="full_name"
    item-value="id"
    label="Produs"
    no-filter
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'ProductSelect',
  props: {
    value: {}
  },
  data () {
    return {
      paginatedProducts: {
        data: []
      },
      loading: false,
      search: ''
    }
  },
  computed: {
    products () {
      return this.paginatedProducts.data
    },
    selectOption: {
      get () {
        return this.value
      },
      set (val) {
        if (typeof val === 'undefined') {
          this.$emit('input', '', this.filterName)
        } else {
          this.$emit('input', val, this.filterName)
        }
      }
    }
  },
  watch: {
    search (val) {
      this.loading = true
      this.loadItems(val)
    }
  },
  methods: {
    loadItems (search) {
      search = search || ''
      this.$http.get(`/products?filter[name]=${search}`)
        .then(({data}) => {
          this.paginatedProducts = data
          this.loading = false
        })

    }
  },
  created () {
    this.loadItems()
  }
}
</script>

<style lang="scss" scoped>
.vs-con-input-label {
  width: auto;
}
</style>
<style lang="scss">
.con-img-upload {
  padding: 0;

  .con-input-upload {
    width: 100%;
    height: 48px;
  }
}
</style>
