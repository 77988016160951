<template>
  <v-sheet>

    <cit-data-table
      :headers="headers"
      resource-path="product-bundles"
    >

      <template v-slot:actions="{table}">
        <product-bundle-creator @created="table.loadItems"  v-if="$store.getters['auth/currentUser'].is_admin"/>
      </template>

      <template v-slot:[`item.actions`]="{item}">
        <action-delete :action-url="`/product-bundles/${item.id}`"/>

      </template>

    </cit-data-table>

  </v-sheet>
</template>

<script>

import CitDataTable from '@/components/layout/CitDataTable'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import ProductBundleCreator from '@/views/ProductBundles/ProductBundleCreator'

export default {
  name: 'ProductBundles',
  components: {
    ProductBundleCreator,
    ActionDelete,
    CitDataTable
  },
  data () {
    return {
      headers: [
        {
          text: 'ID',
          value: 'id',
          width: '80'
        },
        {
          text: 'Denumire',
          value: 'name',
          filterType: 'text',
          sortable: true
        },
        {
          text: 'Pret',
          value: 'price',
          width: '80',
          sortable: true
        }
      ]
    }
  }
}
</script>
